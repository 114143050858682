<template>
  <v-dialog
    v-model="dialogNgrok"
    width="40vw"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-2"
        color="info"
        v-bind="attrs"
        v-on="on"
        @click="botaoNgrok()"
      >
        Configuração NGROK
        <v-divider
          class="mx-4"
          vertical
        />
        <v-icon>mdi-hammer-wrench</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar
        dark
        color="info"
      >
        <v-btn
          class="justify-center"
          icon
          @click="dialogNgrok = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Configuração Ngrok</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <div
          v-for="(file, index) in authFILES"
          :key="index"
        >
          <v-alert
            outlined
            prominent
            color="info"
            text
          >
            <div
              class="overflow-x-hidden"
              style="
                overflow-wrap: break-word;
                width:50%;
              "
            >
              <h4>
                {{ selectedNames[index].name }}
                <v-icon
                  @click="copyText(index)"
                >
                  mdi-content-copy
                </v-icon>
              </h4>
              <pre>{{ file }}</pre>
            </div>
          </v-alert>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import YAML from 'json2yaml'

  export default {
    name: 'ConfigNgrok',
    model: {
      prop: 'selectedNames',
      event: 'update:selectedNames',
    },
    props: {
      selectedNames: {
        type: Array,
        required: true,
      },
    },
    data: () => {
      return {
        authFILES: [],
        dialogNgrok: false,
      }
    },
    methods: {
      async botaoNgrok () {
        const ngrokFile = this.selectedNames.map(user => {
          const authFILE = {
            authtoken: `${user.tunnelConfig.credencial || ''}`,
            update: true,
            update_channel: 'stable',
            tunnels: {
              app: {
                proto: 'http',
                addr: 3002,
                subdomain: `${user.tunnelConfig.url.replace(/^(http:\/\/)/, '').replace(/^(https:\/\/)/, '').split('.')[0]}`,
                bind_tls: true,
              },
            },
          }
          return YAML.stringify(authFILE)
            .replace(/^(---\n)/, '') // remove --- do começo
            .replace(/["'`]/g, '') // remove aspas
            .replace(/^ {2}/, '') // remove espaços duplos no começo do arquivo
            .replace(/\n {2}/g, '\n') // remove espaços duplos após uma quebra de linha
        })
        this.authFILES = await Promise.all(ngrokFile)
      },
      copyText (fileIndex) {
        navigator.clipboard.writeText(`${this.authFILES[fileIndex]}`)
        this.$alert('success', 'Copiado com sucesso')
      },
    },
  }
</script>
